import "./App.css";

import { default as zkAttestorAbiJson } from "./ZKAttestorDemo.json";
import { default as zkAttestorAddressJson } from "./address.json";

import React, { useState, useEffect } from "react";
import { ethers, BigNumber } from "ethers";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
    useAccount,
    useBlockNumber,
    useContractEvent,
    useContractRead,
    useContractWrite,
    useProvider,
    useWaitForTransaction,
} from "wagmi";

const BACKEND_URL = "https://api.zkattestor.xyz/";
const BACKEND_WS_URL = "wss://api.zkattestor.xyz/";
const LOG_SERVER_URL = "https://indexer.zkattestor.xyz/";

function App() {
    const zkAttestorAbi = zkAttestorAbiJson.abi;
    const zkAttestorAddress = zkAttestorAddressJson.address;
    const provider = useProvider();
    const { isConnected } = useAccount();
    const {
        data: currBlockNumber,
        isSuccess,
    } = useBlockNumber({
        cacheTime: 15000,
        staleTime: 15000,
    });

    const { data: lastUpdatedBlock } = useContractRead({
        addressOrName: zkAttestorAddress,
        contractInterface: zkAttestorAbi,
        functionName: "lastUpdatedBlockNumber",
        watch: false,
        cacheTime: 15000,
        staleTime: 15000,
    });

    const { data: lastRootBlock } = useContractRead({
        addressOrName: zkAttestorAddress,
        contractInterface: zkAttestorAbi,
        functionName: "lastRootBlockNumber",
        watch: false,
        cacheTime: 15000,
        staleTime: 15000,
    });

    const [submittedUpdateTxHashes, setSubmittedUpdateTxHashes] = useState([] as string[]);
    const [lastUpdateTxHash, setLastUpdateTxHash] = useState("");
    const [isLastUpdateTxConfirmed, setIsLastUpdateTxConfirmed] = useState(false);
    const [isLastUpdateTxFailed, setIsLastUpdateTxFailed] = useState(false);
    const [isNewUpdateTxValid, setIsNewUpdateTxValid] = useState(true);

    const {
        data: updateData,
        error: updateError,
        write: updateWrite,
        status: updateStatus,
    } = useContractWrite({
        addressOrName: zkAttestorAddress,
        contractInterface: zkAttestorAbi,
        functionName: "update",
        onSuccess(data: { "hash": string }) {
            setSubmittedUpdateTxHashes(
                submittedUpdateTxHashes.concat(data["hash"])
            );
            setLastUpdateTxHash(data["hash"]);
            setIsLastUpdateTxConfirmed(false);
            setIsLastUpdateTxFailed(false);
            setIsNewUpdateTxValid(false);
        },
    });

    const { data: updateReceipt, isSuccess: updateIsSuccess } =
        useWaitForTransaction({
            hash: updateData?.hash,
            onSuccess(data) {
                if (data.status === 0) {
                    setIsLastUpdateTxFailed(true);
                }
                setIsNewUpdateTxValid(true);
            },
        });

    useContractEvent({
        addressOrName: zkAttestorAddress,
        contractInterface: zkAttestorAbi,
        eventName: "UpdateEvent",
        listener: (event) => {
            if (lastUpdateTxHash === event[1]["transactionHash"]) {
                setIsLastUpdateTxConfirmed(true);
                getLogs(0);
                getLogs(10 * 1000);
                getLogs(30 * 1000);
            }
        }
    });

    const [submittedTxHashes, setSubmittedTxHashes] = useState([] as string[]);
    const [lastTxHash, setLastTxHash] = useState("");
    const [isLastTxConfirmed, setIsLastTxConfirmed] = useState(false);
    const [isLastTxFailed, setIsLastTxFailed] = useState(false);
    const [isNewTxValid, setIsNewTxValid] = useState(true);
    const {
        data: attestSlotData,
        error: attestSlotError,
        write: attestSlotWrite,
        status: attestSlotStatus,
    } = useContractWrite({
        addressOrName: zkAttestorAddress,
        contractInterface: zkAttestorAbi,
        functionName: "attestSlot",
        onError(error) {
            console.log(error);
        },
        onSuccess(data) {
            setSubmittedTxHashes(submittedTxHashes.concat(data["hash"]));
            setLastTxHash(data["hash"]);
            setIsLastTxConfirmed(false);
            setIsLastTxFailed(false);
            setIsNewTxValid(false);
        },
    });

    const { data: attestSlotReceipt, isSuccess: attestSlotIsSuccess } =
        useWaitForTransaction({
            hash: attestSlotData?.hash,
            onSuccess(data) {
                if (data.status === 0) {
                    setIsLastTxFailed(true);
                }
                setIsNewTxValid(true);
            },
        });

    useContractEvent({
        addressOrName: zkAttestorAddress,
        contractInterface: zkAttestorAbi,
        eventName: "SlotAttestationEvent",
        listener: (event) => {
            if (lastTxHash === event[4]["transactionHash"]) {
                setIsLastTxConfirmed(true);
                getLogs(0);
                getLogs(10 * 1000);
                getLogs(30 * 1000);
            }
        }
    });

    useEffect(() => {
        const init = async () => {
            await getLogs(0);
        };
        init();
    }, []);

    const [blockNumber, setBlockNumber] = useState(
        // "0xd895ce"  // mainnet
        "0x713d54"          // goerli
    );
    const [isBlockNumberValid, setIsBlockNumberValid] = useState(true);
    const [isBlockNumberTooHigh, setIsBlockNumberTooHigh] = useState(false);

    const setAndValidateBlockNumber = async (val: string) => {
        setBlockNumber(val);

        if (!ethers.utils.isHexString(val)) {
            setIsBlockNumberValid(false);
            setIsBlockNumberTooHigh(false);
        } else {
            let num = BigNumber.from(val);
            //            if (num > BigNumber.from(currBlockNumber)) {
            //                setIsBlockNumberValid(false);
            //                setIsBlockNumberTooHigh(true);
            //            } else {
            //                setIsBlockNumberValid(true);
            //                setIsBlockNumberTooHigh(false);
            //            }
            //            if (!num.eq(BigNumber.from("0xd895ce"))) {
            //                setIsBlockNumberValid(false);
            //            } else {
            //                setIsBlockNumberValid(true);
            //            }
        }
    };

    const [slotAddress, setSlotAddress] = useState(
        // "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb"  // mainnet cryptopunks
        "0xf2d1f94310823fe26cfa9c9b6fd152834b8e7849" // Goerli TestnetDAI
    );
    const [isSlotAddressValid, setIsSlotAddressValid] = useState(true);
    const setAndValidateSlotAddress = async (val: string) => {
        setSlotAddress(val);

        if (!ethers.utils.isHexString(val) || val.length !== 42) {
            setIsSlotAddressValid(false);
        } else {
            setIsSlotAddressValid(true);
        }
    };

    const [slot, setSlot] = useState(
        // "13da86008ba1c6922daee3e07db95305ef49ebced9f5467a0b8613fcc6b343e3" // mainnet cryptopunks
        "0000000000000000000000000000000000000000000000000000000000000002" // Goerli TestnetDAI
    );
    const [isSlotValid, setIsSlotValid] = useState(true);
    const setAndValidateSlot = async (val: string) => {
        setSlot(val);

        if (!ethers.utils.isHexString("0x" + val) || val.length !== 64) {
            setIsSlotValid(false);
        } else {
            setIsSlotValid(true);
        }
    };

    const [inputJson, setInputJson] = useState("");
    const [witnessJson, setWitnessJson] = useState("");
    const [isInputValid, setIsInputValid] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            await getProof();
        };
        if (isInputValid) {
            fetch();
        }
    }, [/*inputJson,*/ isInputValid]);

    const [proofJson, setProofJson] = useState("");
    const [publicJson, setPublicJson] = useState("");
    const [status, setStatus] = useState(0);

    const [attestLogs, setAttestLogs] = useState([] as { "transactionHash": string, "blockNumber": number, "data": string }[]);
    const [updateLogs, setUpdateLogs] = useState([] as { "transactionHash": string, "blockNumber": number, "data": string }[]);
    const [cacheLogs, setCacheLogs] = useState([] as { "transactionHash": string, "blockNumber": number, "data": string }[]);

    const getBlockHashes = async (startBlockNumber: number, endBlockNumber: number) => {
        let blockHashes: string[] = [];
        if (isConnected) {
            const blockNumbers = [
                ...Array(endBlockNumber - startBlockNumber).keys(),
            ].map((i) => i + startBlockNumber);
            const hexBlockNumbers = blockNumbers.map((x) => "0x" + x.toString(16));
            const hashPromises = hexBlockNumbers.map(async (hexNum) => {
                return provider.getBlock(hexNum).then((x) => x["hash"]);
            });
            blockHashes = await Promise.all(hashPromises);
        }
        return blockHashes;
    };

    const update = async () => {
        if (isConnected) {
            const blockHashes = await getBlockHashes(
                lastRootBlock?.toNumber(),
                lastUpdatedBlock?.toNumber() + 2
            );
            await updateWrite({
                args: [BigNumber.from(lastRootBlock), blockHashes],
            });
        }
    };

    const [isGenerating, setIsGenerating] = useState(false);
    const getInput = async () => {
        setIsGenerating(true);
        setIsProofValid(false);
        setIsInputValid(false);
        setStatus(0);
        try {
            const data = {
                addr: slotAddress,
                blockNumber: blockNumber,
                dataSlot: slot,
                network: "goerli",
            };
            const inputJson = await fetch(BACKEND_URL + "generate_storage_input", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }).then((res) => {
                return res.json();
            });
            if (!("input" in inputJson) || !("witness" in inputJson)) {
                throw new Error('Input result did not have both "input" and "witness" keys');
            } else {
                setInputJson(JSON.stringify(inputJson["input"]));
                setWitnessJson(JSON.stringify(inputJson["witness"]));
                setIsInputValid(true);
            }
        } catch (e) {
            setIsGenerating(false);
            console.log(e);
            alert("API call to get input JSON failed.");
        }
    };

    const [isProofValid, setIsProofValid] = useState(false);
    const getProof = async () => {
        console.log("Attempting to create WebSocket connection to server.");
        const ws = new WebSocket(BACKEND_WS_URL + "generate_proof");
        ws.onopen = (event) => {
            console.log(
                "WebSocket connection established. Sending proof input to server."
            );
            ws.send(inputJson);
        };

        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                setProofJson(JSON.stringify(data["proof"], null, 2));
                setPublicJson(JSON.stringify(data["public"], null, 2));
            } catch (e) {
                console.log(e);
                alert("Could not parse server response");
            }
        };

        ws.onerror = (event) => {
            try {
                console.log(event);
            } catch (e) {
                console.log(e);
            }
        };

        ws.onclose = (event) => {
            const code = event.code;
            console.log("WebSocket closed with code " + code);
            setStatus(code);
            setIsGenerating(false);
            if (code !== 1000) {
                const reason: string = event.reason;
                alert("Error in proof generation: " + reason);
            } else {
                setIsProofValid(true);
            }
        };
    };

    const getLogs = async (wait: number) => {
        await new Promise((r) => setTimeout(r, wait));
        fetch(LOG_SERVER_URL + "getLogs", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((resp) => {
                console.log("Log query response status: " + resp.status);
                return resp.json();
            })
            .then((data) => {
                // remove duplicate logs
                let uniq = data
                    .map((log: object) => {
                        return JSON.stringify(log);
                    })
                    .filter((x: string, i: number, a: string) => a.indexOf(x) === i)
                    .map((log: string) => JSON.parse(log));

                let attest_logs = uniq.filter(
                    (log: { "topics": string }) =>
                        JSON.parse(log["topics"])[0] ===
                        ethers.utils.id(
                            "SlotAttestationEvent(uint32,address,uint256,uint256)"
                        )
                );
                let update_logs = uniq.filter(
                    (log: { "topics": string }) =>
                        JSON.parse(log["topics"])[0] ===
                        ethers.utils.id("UpdateEvent(uint32)")
                );
                let cache_logs = uniq.filter(
                    (log: { "topics": string }) =>
                        JSON.parse(log["topics"])[0] ===
                        ethers.utils.id("HashCacheEvent(uint32,bytes32)")
                );

                // sort logs by Goerli block
                attest_logs.sort((log: { "blockNumber": number }) => -log["blockNumber"]);
                update_logs.sort((log: { "blockNumber": number }) => -log["blockNumber"]);
                cache_logs.sort((log: { "blockNumber": number }) => -log["blockNumber"]);

                setAttestLogs(attest_logs);
                setUpdateLogs(update_logs);
                setCacheLogs(cache_logs);
            });
    };

    const getMerkleProofAndSide = async (
        idx: number,
        merkleBlockNumberStart: number,
        merkleBlockNumberEnd: number,
        network: string
    ): Promise<[string[], BigNumber]> => {
        console.log("idx", idx, "network", network);
        if (network == "goerli") {
            const blockHashes = await getBlockHashes(
                merkleBlockNumberStart,
                merkleBlockNumberEnd
            );
            let hashes: string[][] = [[], [], [], [], [], [], [], []];
            for (var i = 0; i < 8; i++) {
                for (var j = 0; j < (1 << (7 - i)); j++) {
                    if (i == 0) {
                        hashes[i].push(ethers.utils.solidityKeccak256(["bytes32", "bytes32"], [blockHashes[2 * j], blockHashes[2 * j + 1]]));
                    } else {
                        hashes[i].push(ethers.utils.solidityKeccak256(["bytes32", "bytes32"], [hashes[i - 1][2 * j], hashes[i - 1][2 * j + 1]]));
                    }
                }
            }

            let merkleProof: string[] = [];
            for (var i = 0; i < 8; i++) {
                if (i == 0) {
                    if ((idx >> i) % 2 == 0) {
                        merkleProof.push(blockHashes[idx + 1]);
                    } else {
                        merkleProof.push(blockHashes[idx - 1]);
                    }
                } else {
                    if ((idx >> i) % 2 == 0) {
                        merkleProof.push(hashes[i - 1][(idx >> i) + 1]);
                    } else {
                        merkleProof.push(hashes[i - 1][(idx >> i) - 1]);
                    }
                }
            }
            let side = BigNumber.from(idx);
            return [merkleProof, side];
        }
        const merkleProof = [
            ethers.constants.HashZero,
            ethers.constants.HashZero,
            ethers.constants.HashZero,
            ethers.constants.HashZero,
            ethers.constants.HashZero,
            ethers.constants.HashZero,
            ethers.constants.HashZero,
            ethers.constants.HashZero,
        ];
        const side = BigNumber.from(0);

        return [merkleProof, side];
    };

    const attestSlot = async () => {
        if (isConnected) {
            try {
                const proof = JSON.parse(proofJson);
                const pub = JSON.parse(publicJson);
                const witness = JSON.parse(witnessJson);

                const pf = [
                    BigNumber.from(proof["pi_a"][0]),
                    BigNumber.from(proof["pi_a"][1]),
                    BigNumber.from(proof["pi_b"][0][1]),
                    BigNumber.from(proof["pi_b"][0][0]),
                    BigNumber.from(proof["pi_b"][1][1]),
                    BigNumber.from(proof["pi_b"][1][0]),
                    BigNumber.from(proof["pi_c"][0]),
                    BigNumber.from(proof["pi_c"][1]),
                ];
                const publicHash = BigNumber.from(pub[0]);
                const blockHash = BigNumber.from("0x" + witness["blockHash"]);
                const addr = witness["addr"];
                const slot = BigNumber.from("0x" + witness["slot"]);
                const pfBlockNumber = BigNumber.from("0x" + witness["pfBlockNumber"]);
                const slotValue = BigNumber.from("0x" + witness["slotValue"]);

                const blockNumber = pfBlockNumber.toNumber();
                const merkleBlockNumberStart = blockNumber - (blockNumber % 256);
                const merkleBlockNumberEnd = merkleBlockNumberStart + 256;
                const [merkleProof, side] = await getMerkleProofAndSide(blockNumber % 256,
                    merkleBlockNumberStart,
                    merkleBlockNumberEnd,
                    "goerli");
                await attestSlotWrite({
                    args: [
                        pf,
                        publicHash,
                        blockHash,
                        addr,
                        slot,
                        pfBlockNumber,
                        slotValue,
                        merkleProof,
                        side,
                    ],
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <>
            <div className="App">
                <header className="App-header"></header>
                <div className="grid grid-cols-2 gap-x-16 gap-y-8 p-6 sm:px-56 sm:py-20">
                    <div className="text-3xl sm:text-4xl justify-self-start font-bold">
                        ZKAttestor
                    </div>
                    <div className="justify-self-end">
                        <ConnectButton label="Connect" />
                    </div>

                    <div className="px-8 pb-8 pt-6 bg-white rounded-lg shadow-lg text-left col-span-2">
                        <div className="pb-2">
                            <label className="text-sm text-black font-medium">
                                Block Number
                            </label>
                            <input
                                className={`w-full p-2 rounded-lg text-gray-500 text-sm ${isBlockNumberValid
                                    ? ""
                                    : "border-pink-500 focus:border-pink-500 focus:ring-pink-500"
                                    }`}
                                placeholder="Enter block number"
                                type="text"
                                value={blockNumber}
                                onChange={(e) => {
                                    setAndValidateBlockNumber(e.currentTarget.value);
                                }}
                                id="blockNumber"
                            />
                            {isBlockNumberValid || isBlockNumberTooHigh ? (
                                ""
                            ) : (
                                <span className="text-xs text-red-700">
                                    Invalid block number
                                </span>
                            )}
                            {isBlockNumberTooHigh ? (
                                <span className="text-xs text-red-700">
                                    The block you entered has not occurred yet.
                                </span>
                            ) : ("")}
                        </div>
                        <div className="pb-2">
                            <label className="text-sm text-black font-medium">Address</label>
                            <input
                                className={`w-full p-2 rounded-lg text-gray-500 text-sm ${isSlotAddressValid
                                    ? ""
                                    : "border-pink-500 focus:border-pink-500 focus:ring-pink-500"
                                    }`}
                                placeholder="Address"
                                type="text"
                                value={slotAddress}
                                onChange={(e) => {
                                    setAndValidateSlotAddress(e.currentTarget.value);
                                }}
                                id="address"
                            />
                            {isSlotAddressValid ? (
                                ""
                            ) : (
                                <span className="text-xs text-red-700">Invalid address</span>
                            )}
                        </div>
                        <div className="pb-2">
                            <label className="text-sm text-black font-medium">Slot</label>
                            <input
                                className={`w-full p-2 rounded-lg text-gray-500 text-sm ${isSlotValid
                                    ? ""
                                    : "border-pink-500 focus:border-pink-500 focus:ring-pink-500"
                                    }`}
                                placeholder="Slot"
                                type="text"
                                value={slot}
                                onChange={(e) => {
                                    setAndValidateSlot(e.currentTarget.value);
                                }}
                                id="slot"
                            />
                            {isSlotValid ? (
                                ""
                            ) : (
                                <span className="text-xs text-red-700">Invalid slot</span>
                            )}
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-4 mt-4">
                            <button
                                type="submit"
                                disabled={
                                    isGenerating ||
                                    !isBlockNumberValid ||
                                    !isSlotAddressValid ||
                                    !isSlotValid
                                }
                                className="w-full px-5 py-3 text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 rounded-lg"
                                onClick={getInput}
                            >
                                <span className="font-medium">Generate Proof</span>
                            </button>
                            <div className="px-4 py-1 text-sm text-gray-600 sm:col-span-3">
                                {isInputValid ? (
                                    <div>
                                        {"\u{1f914}"}{" "}
                                        <a
                                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                                inputJson
                                            )}`}
                                            download="input.json"
                                        >
                                            Input
                                        </a>{" "}
                                        created, generating proof on server...
                                        {isGenerating ? (
                                            <div role="status">
                                                <svg
                                                    className="inline mr-2 w-4 h-4 animate-spin fill-blue-600"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="white"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"
                                                    />
                                                </svg>
                                                <span className="sr-only">
                                                    This usually takes a few minutes...
                                                </span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}
                                {isProofValid ? (
                                    <div>
                                        {"\u{1f4dc}"}{" "}
                                        <a
                                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                                proofJson
                                            )}`}
                                            download="proof.json"
                                        >
                                            Proof
                                        </a>{" "}
                                        and{" "}
                                        <a
                                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                                publicJson
                                            )}`}
                                            download="public.json"
                                        >
                                            public witnesses
                                        </a>{" "}
                                        generated, ready to submit to blockchain!
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div className="mt-4 grid grid-cols-1 sm:grid-cols-4">
                            <button
                                type="submit"
                                disabled={
                                    !isInputValid ||
                                    !isConnected ||
                                    !isProofValid ||
                                    !isNewTxValid
                                }
                                className="w-full px-5 py-3 text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 rounded-lg"
                                onClick={attestSlot}
                            >
                                <span className="font-medium">Add Slot Attestation</span>
                            </button>
                            <div className="px-4 py-1 text-sm text-gray-600 sm:col-span-3">
                                {attestSlotStatus === "success" &&
                                    !isLastTxConfirmed &&
                                    !isLastTxFailed ? (
                                    <div>
                                        {"\u{1f680}"} Attestation{" "}
                                        <a href={`https://goerli.etherscan.io/tx/${lastTxHash}`}>
                                            transaction
                                        </a>{" "}
                                        sent, waiting for confirmation...
                                    </div>
                                ) : (
                                    ""
                                )}
                                {isLastTxConfirmed && !isLastTxFailed && !isGenerating ? (
                                    <div>
                                        {"\u{1f389}"} Attestation{" "}
                                        <a href={`https://goerli.etherscan.io/tx/${lastTxHash}`}>
                                            transaction
                                        </a>{" "}
                                        confirmed!
                                    </div>
                                ) : (
                                    ""
                                )}
                                {isLastTxFailed ? (
                                    <div>
                                        {"\u{274c}"} Attestation{" "}
                                        <a href={`https://goerli.etherscan.io/tx/${lastTxHash}`}>
                                            transaction
                                        </a>{" "}
                                        failed, try again.
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            {isInputValid && isProofValid && !isConnected ? (
                                <span className="pt-1 text-xs text-red-700">
                                    Connect wallet to submit attestation on-chain.
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className="p-8 bg-white rounded-lg col-span-2 overflow-auto">
                        <div className="text-lg text-black text-left pb-4 font-semibold">
                            Previous Attestations
                        </div>
                        <table className="text-sm table-fixed border">
                            <thead>
                                <tr className="px-4 py-2 font-medium text-left text-black">
                                    <th className="p-2 border-b w-8">Link</th>
                                    <th className="p-2 border-b">Attestation Block Number</th>
                                    <th className="p-2 border-b">Historical Block Number</th>
                                    <th className="p-2 border-b">Address</th>
                                    <th className="p-2 border-b">Slot</th>
                                    <th className="p-2 border-b">SlotValue</th>
                                </tr>
                            </thead>
                            <tbody className="text-black text-left font-medium">
                                {attestLogs.map((row) => (
                                    <tr
                                        className="p-4 font-medium text-left text-black hover:bg-stone-100"
                                        key={JSON.stringify(row)}
                                    >
                                        <td className="p-2 border-b">
                                            <a
                                                href={`https://goerli.etherscan.io/tx/${row["transactionHash"]}`}
                                            >
                                                link
                                            </a>
                                        </td>
                                        <td className="p-2 border-b">{row["blockNumber"]}</td>
                                        <td className="p-2 border-b">
                                            {BigNumber.from(
                                                row["data"].toString().slice(0, 66)
                                            ).toString()}
                                        </td>
                                        <td className="p-2 border-b">
                                            <div className="group relative">
                                                <p className="truncate sm:w-40">
                                                    {"0x" +
                                                        row["data"].toString().slice(66 + 24, 66 + 64)}
                                                </p>
                                                <div className="tooltip-text hidden group-hover:block absolute text-center -left-20 px-1 rounded-md bg-black text-white">
                                                    {"0x" +
                                                        row["data"].toString().slice(66 + 24, 66 + 64)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="p-2 border-b">
                                            <div className="group relative">
                                                <p className="truncate sm:w-40">
                                                    {row["data"].toString().slice(66 + 64, 66 + 2 * 64)}
                                                </p>
                                                <span className="tooltip-text hidden group-hover:block absolute text-center -left-28 px-1 rounded-md bg-black text-white">
                                                    {row["data"].toString().slice(66 + 64, 66 + 2 * 64)}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="p-2 border-b">
                                            <div className="group relative">
                                                <p className="truncate sm:w-120">
                                                    {row["data"]
                                                        .toString()
                                                        .slice(66 + 2 * 64, 66 + 3 * 64)}
                                                </p>
                                                <p className="tooltip-text hidden group-hover:block absolute text-center -left-28 px-1 rounded-md bg-black text-white">
                                                    {row["data"]
                                                        .toString()
                                                        .slice(66 + 2 * 64, 66 + 3 * 64)}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="grid col-span-2 grid-cols-2 rounded-lg bg-white">
                        <div className="p-4 sm:p-8 bg-white rounded-lg pb-2 sm:pb-4">
                            <dt className="text-md sm:text-lg text-black">
                                Last Updated Block
                            </dt>
                            <dd className="text-3xl sm:text-4xl font-extrabold text-blue-600">
                                {lastUpdatedBlock?.toString()}
                            </dd>
                        </div>
                        <div className="p-4 sm:p-8 bg-white rounded-lg pb-2 sm:pb-4">
                            <dt className="text-md sm:text-lg text-black">Last Root Block</dt>
                            <dd className="text-3xl sm:text-4xl font-extrabold text-blue-600">
                                {lastRootBlock?.toString()}
                            </dd>
                        </div>

                        <div className="mt-4 col-span-2 p-4 sm:p-8 sm:pt-0">
                            <button
                                type="submit"
                                className="inline-flex items-center justify-center w-full px-5 py-3 text-white bg-blue-600 hover:bg-blue-700 rounded-lg disabled:bg-blue-300"
                                disabled={!isConnected || !isNewUpdateTxValid}
                                onClick={update}
                            >
                                <span className="font-medium"> Update Block Hashes </span>
                            </button>
                            {updateStatus === "success" &&
                                !isLastUpdateTxConfirmed &&
                                !isLastUpdateTxFailed ? (
                                <div className="pt-4 text-black">
                                    {"\u{1f680}"} Update{" "}
                                    <a
                                        href={`https://goerli.etherscan.io/tx/${lastUpdateTxHash}`}
                                    >
                                        transaction
                                    </a>{" "}
                                    sent, waiting for confirmation...
                                </div>
                            ) : (
                                ""
                            )}
                            {isLastUpdateTxConfirmed && !isLastUpdateTxFailed ? (
                                <div className="pt-4 text-black">
                                    {"\u{1f389}"} Update{" "}
                                    <a
                                        href={`https://goerli.etherscan.io/tx/${lastUpdateTxHash}`}
                                    >
                                        transaction
                                    </a>{" "}
                                    confirmed!
                                </div>
                            ) : (
                                ""
                            )}
                            {isLastUpdateTxFailed ? (
                                <div className="pt-4 text-black">
                                    {"\u{274c}"} Update{" "}
                                    <a
                                        href={`https://goerli.etherscan.io/tx/${lastUpdateTxHash}`}
                                    >
                                        transaction
                                    </a>{" "}
                                    failed, try again.
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className="p-8 bg-white rounded-lg col-span-2 overflow-auto sm:overflow-fixed">
                        <div className="text-lg text-black text-left pb-4 font-semibold">
                            Previous Updates
                        </div>
                        <table className="text-sm table-fixed w-full border">
                            <thead>
                                <tr className="px-4 py-2 font-medium text-left text-black">
                                    <th className="p-2 border-b">Link</th>
                                    <th className="p-2 border-b">Update Block Number</th>
                                    <th className="p-2 border-b">Starting Block Number</th>
                                    <th className="p-2 border-b">Ending Block Number</th>
                                </tr>
                            </thead>
                            <tbody className="text-black text-left font-medium">
                                {updateLogs.map((row) => (
                                    <tr
                                        className="p-4 font-medium text-left text-black hover:bg-stone-100"
                                        key={JSON.stringify(row)}
                                    >
                                        <td className="p-2 border-b">
                                            <a
                                                href={`https://goerli.etherscan.io/tx/${row["transactionHash"]}`}
                                            >
                                                link
                                            </a>
                                        </td>
                                        <td className="p-2 border-b">{row["blockNumber"]}</td>
                                        <td className="p-2 border-b">
                                            {BigNumber.from(
                                                row["data"].toString().slice(0, 66)
                                            ).toString()}
                                        </td>
                                        <td className="p-2 border-b">{row["blockNumber"] - 1}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    <div className="p-8 bg-white rounded-lg col-span-2 overflow-auto sm:overflow-auto">
                        <div className="text-lg text-black text-left pb-4 font-semibold">
                            Cached Merkle Roots
                        </div>
                        <table className="text-sm table-fixed border">
                            <thead>
                                <tr className="px-4 py-2 font-medium text-left text-black">
                                    <th className="p-2 border-b">Link</th>
                                    <th className="p-2 border-b">Update Block Number</th>
                                    <th className="p-2 border-b">Starting Block Number</th>
                                    <th className="p-2 border-b">Merkle Root</th>
                                </tr>
                            </thead>
                            <tbody className="text-black text-left font-medium">
                                {cacheLogs.map((row) => (
                                    <tr
                                        className="p-4 font-medium text-left text-black hover:bg-stone-100"
                                        key={JSON.stringify(row)}
                                    >
                                        <td className="p-2 border-b">
                                            <a
                                                href={`https://goerli.etherscan.io/tx/${row["transactionHash"]}`}
                                            >
                                                link
                                            </a>
                                        </td>
                                        <td className="p-2 border-b">{row["blockNumber"]}</td>
                                        <td className="p-2 border-b">
                                            {BigNumber.from(
                                                row["data"].toString().slice(0, 66)
                                            ).toString()}
                                        </td>
                                        <td className="p-2 border-b">
                                            {row["data"].toString().slice(66, 130)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
