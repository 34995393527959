import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ethers, getDefaultProvider } from "ethers";
import '@rainbow-me/rainbowkit/styles.css';
import { connectorsForWallets, wallet, RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig, defaultChains } from 'wagmi';
import { infuraProvider } from 'wagmi/providers/infura';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const infuraId = process.env.REACT_APP_INFURA_ID;
const alchemyId = process.env.REACT_APP_ALCHEMY_ID;

const { chains, provider } = configureChains(
    [chain.goerli],
    [
        //        infuraProvider({ infuraId, priority: 0 }),
        alchemyProvider({ alchemyId, priority: 1 }),
        //        publicProvider({ priority: 2 })
    ]
);

const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    chains
});

const wagmiClient = createClient({
    connectors,
    provider
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
                <App />
            </RainbowKitProvider>
        </WagmiConfig>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
